import React, {useState} from "react";

export const Navigation = (props) => {
  const [isLogoVisible, setIsLogoVisible] = useState(true);

  const handleToggle = () => {
    // Verifica se o dispositivo é móvel
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      setIsLogoVisible(prevState => !prevState);
    }
  };
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={handleToggle}
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {isLogoVisible && (
            <a className="navbar-brand page-scroll" href="#page-top">
              <img src="img/logo-paris-2.png" alt="Giro Paris Turismo" />
            </a>
          )}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll">
                SOBRE NÓS
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                SERVIÇOS
              </a>
            </li>
            <li>
              <a href="#passeios" className="page-scroll">
                PASSEIOS
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                GALERIA
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                FALE CONOSCO
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
