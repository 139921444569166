import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/giro-paris-turismo.jpg" className="img-responsive" alt="Giro Paris Turismo" style={{borderRadius: '15px'}} />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text" style={{paddingTop: '20px'}}>
              <h2>QUEM SOMOS?</h2>
              <p>{props.data ? props.data.paragraph : "Carregando..."}</p>
              {/* <h3>Por que ir com a Giro Paris Turismo?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "Carregando..."}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "Carregando.."}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
