import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";

export const Header = (props) => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000
  };

  return (
    <header id="header">
      <Slider {...sliderSettings} style={{ maxWidth: '100%' }} className="slider">
        <div className="intro-slide">
          <div className="intro-text" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/carousel_home/paris_4.jpg)`, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <h1 className="titulo-texto">
              {props.data ? props.data.title : "Carregando"}
              <span></span>
            </h1>
            <p style={{ backgroundColor: 'rgba(37, 37, 37, 0.4)', borderRadius: '10px', fontSize: '16px' }}>{props.data ? props.data.paragraph : "Carregando"}</p>
            <a
              href="#contact"
              className="btn btn-custom btn-lg page-scroll"
            >
              FAÇA SEU ORÇAMENTO
            </a>{" "}
          </div>
        </div>
        <div className="intro-slide">
          <div className="intro-text" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/carousel_home/paris_8.jpg)`, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <h1>
              {props.data ? props.data.title : "Carregando"}
              <span></span>
            </h1>
            <p style={{ backgroundColor: 'rgba(37, 37, 37, 0.4)', borderRadius: '10px', fontSize: '16px' }}>{props.data ? props.data.paragraph : "Carregando"}</p>
            <a
              href="#contact"
              className="btn btn-custom btn-lg page-scroll"
            >
              FAÇA SEU ORÇAMENTO
            </a>{" "}
          </div>
        </div>
        <div className="intro-slide">
          <div className="intro-text" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/carousel_home/paris_12.jpg)`, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <h1>
              {props.data ? props.data.title : "Carregando"}
              <span></span>
            </h1>
            <p style={{ backgroundColor: 'rgba(37, 37, 37, 0.4)', borderRadius: '10px', fontSize: '16px' }}>{props.data ? props.data.paragraph : "Carregando"}</p>
            <a
              href="#contact"
              className="btn btn-custom btn-lg page-scroll"
            >
              FAÇA SEU ORÇAMENTO
            </a>{" "}
          </div>
        </div>
        <div className="intro-slide">
          <div className="intro-text" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/carousel_home/paris_7.jpg)`, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
            <h1>
              {props.data ? props.data.title : "Carregando"}
              <span></span>
            </h1>
            <p style={{ backgroundColor: 'rgba(37, 37, 37, 0.4)', borderRadius: '10px', fontSize: '16px' }}>{props.data ? props.data.paragraph : "Carregando"}</p>
            <a
              href="#contact"
              className="btn btn-custom btn-lg page-scroll"
            >
              FAÇA SEU ORÇAMENTO
            </a>{" "}
          </div>
        </div>
        <div className="intro-slide">
          <div className="intro-text" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/carousel_home/paris_13.jpg)`, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
            <h1>
              {props.data ? props.data.title : "Carregando"}
              <span></span>
            </h1>
            <p style={{ backgroundColor: 'rgba(37, 37, 37, 0.4)', borderRadius: '10px', fontSize: '16px' }}>{props.data ? props.data.paragraph : "Carregando"}</p>
            <a
              href="#contact"
              className="btn btn-custom btn-lg page-scroll"
            >
              FAÇA SEU ORÇAMENTO
            </a>{" "}
          </div>
        </div>
      </Slider>
    </header>
  );
};
