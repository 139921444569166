import React from 'react';

export const CardPasseio = ({ item, openModal }) => {
    const isMobile = window.innerWidth <= 768;

    return (
        <div className="card" style={{ backgroundColor: '#fff', height: '100%', width: '100%', borderRadius: '20px', position: 'relative' }}>
            <img
                src={item.image}
                alt='Slider'
                className="card-img-top rounded-circle"
                style={{ width: '100%', height: '240px', objectFit: 'cover', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
            />
            <button className='buttonPasseios' onClick={() => openModal(item)}>MAIS DETALHES</button>
            <div className="card-body" style={!isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '15px' } : { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px' }}>
                <h5 className="card-title" style={!isMobile ? { color: 'black', fontSize: '20px', fontFamily: '"Raleway", sans-serif' } : { color: 'black', fontSize: '17px', fontFamily: '"Raleway", sans-serif' }}>{item.nome}</h5>
                <p className="card-text" style={!isMobile ? { color: 'black', fontSize: '15px', fontFamily: '"Raleway", sans-serif', textAlign: 'left' } : { color: 'black', fontSize: '14px', fontFamily: '"Raleway", sans-serif', textAlign: 'left' }}>{item.descricao}</p>
            </div>
        </div>
    );
};
