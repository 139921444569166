import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris1.jpeg",
  "smallImage": "img/portfolio/small/paris1.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris28.jpeg",
  "smallImage": "img/portfolio/small/paris2.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris2.jpeg",
  "smallImage": "img/portfolio/small/paris13.jpeg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris35.jpeg",
  "smallImage": "img/portfolio/small/paris10.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris44.jpeg",
  "smallImage": "img/portfolio/small/paris5.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris25.jpeg",
  "smallImage": "img/portfolio/small/paris7.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris19.jpeg",
  "smallImage": "img/portfolio/small/paris6.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris29.jpeg",
  "smallImage": "img/portfolio/small/paris4.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris27.jpeg",
  "smallImage": "img/portfolio/small/paris11.jpeg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris15.jpeg",
  "smallImage": "img/portfolio/small/paris8.jpg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris11.jpeg",
  "smallImage": "img/portfolio/small/paris12.jpeg"
},
{
  "title": "VER FOTO",
  "largeImage": "img/portfolio/large/paris21.jpeg",
  "smallImage": "img/portfolio/small/paris9.jpg"
}];

export const Gallery = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const moveNext = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  const movePrev = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galeria</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {images.map((image, index) => (
              <div key={`${image.title}-${index}`} className="col-sm-6 col-md-4 col-lg-3">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    <a onClick={() => openLightbox(index)} title={image.title} style={{ cursor: 'pointer', position: 'relative', display: 'block' }}>
                      <img src={image.smallImage} className="img-responsive" alt={image.title} style={{ width: '100%', height: '200px', objectFit: "cover", borderRadius: '7px' }} />
                      <div className="hover-text">
                        <h4 style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(2px)', position: 'absolute', bottom: 0, left: 0, width: '100%', padding: '10px 0', margin: 0 }}>{image.title}</h4>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].largeImage}
          nextSrc={images[(photoIndex + 1) % images.length].largeImage}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].largeImage}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={movePrev}
          onMoveNextRequest={moveNext}
          imageTitle={images[photoIndex].title}
          reactModalStyle={{ overlay: { zIndex: 2000 }, content: { top: '100px' } }} // Ajuste aqui para movê-lo para baixo
        />
      )}
    </div>
  );

};
