import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Modal from "./Modal/Modal";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { CardPasseio } from "./CardPasseios";

export const Passeios = (props) => {

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpen(false);
  };



  const isMobile = window.innerWidth <= 1000;

  return (
    <div id="passeios" className="text-center">
      <div className="container" style={{ width: '100%' }}>
        <div className="section-title">
          <h2>ALGUNS DOS NOSSOS PASSEIOS</h2>
        </div>

        <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={!isMobile ? 3 : 1}
            spaceBetween={40}
            slidesPerGroup={!isMobile ? 3 : 1}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{
              clickable: true,
              el: '.swiper-pagination',
            }}
            style={!isMobile ? { width: '75%', height: '550px', paddingInline: '50px' } : { width: '100%', height: '510px', paddingInline: '50px' }}
          >
            {props.data
              ? props.data.map((item) => (
                <SwiperSlide key={item.id}>
                  <CardPasseio item={item} openModal={openModal} />
                </SwiperSlide>
              )) : "Carregando"}
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-pagination"></div>
          </Swiper>
          {modalOpen && (
            <Modal item={selectedItem} closeModal={closeModal} />
          )}

        </div>

      </div>
    </div>
  );
};
