import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [weather, setWeather] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString('en-GB', { timeZone: 'Europe/Paris' }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });
  const isMobile = window.innerWidth <= 768;

  const handleSubmit = (e) => {
    e.preventDefault();

    const lastSubmissionTime = sessionStorage.getItem("lastSubmissionTime");
    const currentTime = Date.now();

    // Verifica se o tempo entre as submissões é maior que 10 minutos
    if (lastSubmissionTime && currentTime - lastSubmissionTime < 600000) {
      alert("Por favor, aguarde 10 minutos antes de enviar outro e-mail.");
      return;
    }

    emailjs.sendForm("service_k7v4dkm", "template_11qs5og", e.target, "a0UA7mjLWA3n1uZi9").then(
      (result) => {
        alert(
          "O seu contato foi enviado com sucesso! Basta aguardar que entraremos em contato no e-mail que você enviou. Obrigado :)"
        );
        clearState();
        sessionStorage.setItem("lastSubmissionTime", currentTime); // Armazena o horário da última submissão
      },
      (error) => {
        alert("Ocorreu algum erro ao enviar esse e-mail. Erro::" + error.text);
      }
    );
  };

  useEffect(() => {
    // Fetch weather data from Open-Meteo
    fetch(`https://api.open-meteo.com/v1/forecast?latitude=48.8566&longitude=2.3522&current_weather=true`)
      .then(response => response.json())
      .then(data => {
        setWeather(data.current_weather);
      });

    // Fetch exchange rate data (using an example without a key)
    fetch('https://api.exchangerate-api.com/v4/latest/EUR')
      .then(response => response.json())
      .then(data => setExchangeRate(data.rates.BRL));

    // Update current time every second
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString('en-GB', { timeZone: 'Europe/Paris' }));
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div id="contact" className="text-center">
        <div className="container">
          <div className="section-title fale-conosco">
            <h2>FALE CONOSCO</h2>
            <p>
              Preencha os dados abaixo com sua solicitação que em breve entraremos em contato. Você também pode clicar na opção de whatsapp para conversar diretamente conosco.
            </p>
          </div>
          <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '40px' }}>
            <form name="sentMessage" validate onSubmit={handleSubmit} className="formulario" style={{ width: '100%', maxWidth: '600px', backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
              <div className="row">
                <div className="col-md-6" style={{ paddingBottom: '10px' }}>
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Nome completo"
                      style={{
                        height: '50px',
                        backgroundColor: '#fff',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                        padding: '10px',
                        fontSize: '16px',
                        color: '#333',
                        width: '100%',
                        boxSizing: 'border-box'

                      }}
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6" style={{ paddingBottom: '10px' }}>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      style={{
                        height: '50px',
                        backgroundColor: '#fff',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                        padding: '10px',
                        fontSize: '16px',
                        color: '#333',
                        width: '100%',
                        boxSizing: 'border-box'
                      }}
                      placeholder="Seu e-mail para contato"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group" style={{ paddingBottom: '10px' }}>
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    padding: '10px',
                    fontSize: '16px',
                    color: '#333',
                    width: '100%',
                    boxSizing: 'border-box'
                  }}
                  rows="4"
                  placeholder="Digite sua solicitação. (Exemplo: Quero um orçamento)"
                  required
                  onChange={handleChange}
                ></textarea>
              </div>
              <div id="success"></div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', gap: '20px' }}>
                <button type="submit" className="btn btn-custom" style={{
                  backgroundColor: '#142C4C',
                  color: 'white',
                  borderRadius: '20px',
                  padding: '10px 20px',
                  fontSize: '17px',
                  fontFamily: 'Raleway',
                  cursor: 'pointer',
                  border: 'none',
                  transition: 'background-color 0.3s, color 0.3s'
                }}>
                  ENVIAR
                </button>
              </div>
            </form>
          </div>

          <div style={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.4)', borderRadius: '20px' }}>
            {
              !isMobile ?
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-around' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="contact-item">
                      <h3 style={!isMobile ? { color: 'white', fontWeight: "bold" } : { display: 'none' }}>Contato: </h3>
                    </div>
                    <div className="contact-item">
                      <p style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <span style={{ fontSize: '30px' }}>
                          <i className="fa fa-phone"></i>
                        </span>{" "}
                        <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', fontWeight: "bold" }}>
                          {props.data ? props.data.phone : "Carregando"}
                        </p>
                      </p>
                    </div>
                    <div className="contact-item" style={{ display: 'flex' }}>
                      <p style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <span style={{ fontSize: '30px' }}>
                          <i className="fa fa-envelope-o"></i>
                        </span>{" "}
                        <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', fontWeight: "bold" }}>
                          contato@giroparisturismo.com
                        </p>
                      </p>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="contact-item">
                      <h3 style={!isMobile ? { color: 'white', fontWeight: "bold" } : { display: 'none' }}>Paris agora: </h3>
                    </div>
                    <div className="contact-item" >
                      <p style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: '10px' }}>
                        <span style={{ fontSize: '18px' }}>
                          Horário e Temperatura:
                        </span>
                        <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', fontWeight: "bold" }}>
                          {currentTime} - {weather ? `${weather.temperature}°C` : "Carregando"}
                        </p>
                      </p>
                    </div>
                    <div className="contact-item" style={{ display: 'flex' }}>
                      <p style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: '10px' }}>
                        <span style={{ fontSize: '18px' }}>
                          Conversão do Euro:
                        </span>{" "}
                        <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', fontWeight: "bold" }}>
                          {exchangeRate ? `1 EUR = R$${exchangeRate} BRL` : "Carregando"}
                        </p>
                      </p>
                    </div>
                  </div>


                  <div className="social" style={{ display: 'flex', flexDirection: 'column', borderRadius: '20px', padding: '20px' }}>
                    <div className="contact-item">
                      <h3 style={!isMobile ? { color: 'white', fontWeight: "bold" } : { color: 'black', fontWeight: "bold" }}>Redes Sociais:</h3>
                    </div>
                    <ul>
                      <li className="whatsapp">
                        <a href={props.data ? props.data.whatsapp : "/"} target="_blank">
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </li>
                      <li className="instagram">
                        <a href={props.data ? props.data.instagramlink : "/"} target="_blank">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                :
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>

                  <div>
                    <h3 style={{
                      fontFamily: 'sans-serif', fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(4, 1, 50, 0.8)'
                    }}>Contatos: </h3>
                  </div>

                  <div style={{ width: '100%', flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                      <div style={{ width: '50px', height: '50px', alignItems: 'center', justifyContent: 'center' }}>
                        <i className="fa fa-phone" style={{ fontSize: '30px', color: '#fff' }}></i>
                      </div>

                      <p style={{
                        fontFamily: 'sans-serif', color: '#fff', textShadow: '2px 2px 4px rgba(4, 1, 50, 0.8)', fontSize: '19px', fontWeight
                          : 'bold'
                      }}>  {props.data ? props.data.phone : "Carregando"} </p>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                      <div style={{ width: '50px', height: '50px', alignItems: 'center', justifyContent: 'center' }}>
                        <i className="fa fa-instagram" style={{ fontSize: '30px', color: '#fff' }}></i>
                      </div>

                      <p style={{
                        fontFamily: 'sans-serif', color: '#fff', textShadow: '2px 2px 4px rgba(4, 1, 50, 0.8)', fontSize: '19px', fontWeight
                          : 'bold'
                      }}>  {props.data ? props.data.instagram : "Carregando"} </p>
                    </div>
                    <div className="social" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                      <ul>
                        <li className="whatsapp">
                          <a href={props.data ? props.data.whatsapp : "/"} target="_blank">
                            <i className="fa fa-whatsapp"></i>
                          </a>
                        </li>
                        <li className="instagram">
                          <a href={props.data ? props.data.instagramlink : "/"} target="_blank">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '20px', padding: '20px' }}>
                    <div className="contact-item">
                      <h3 style={!isMobile ? { color: 'white', fontWeight: "bold" } : { display: 'none' }}>Paris agora: </h3>
                    </div>
                    <div className="contact-item" >
                      <p style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: '10px' }}>
                        <span style={{ fontSize: '18px' }}>
                          Horário e Temperatura:
                        </span>
                        <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', fontWeight: "bold" }}>
                          {currentTime} - {weather ? `${weather.temperature}°C` : "Carregando"}
                        </p>
                      </p>
                    </div>
                    <div className="contact-item" style={{ display: 'flex' }}>
                      <p style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: '10px' }}>
                        <span style={{ fontSize: '18px' }}>
                          Conversão do Euro:
                        </span>{" "}
                        <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', fontWeight: "bold" }}>
                          {exchangeRate ? `1 EUR = R$${exchangeRate} BRL` : "Carregando"}
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p style={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}>
            Giro Paris Turismo &copy; {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div >
  );
};
