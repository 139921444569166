import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>NOSSOS SERVIÇOS</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4">
                {" "}
                <i className={d.icon}></i>
                <div className="service-desc" >
                  <h3 style={{ color: "#567783", textShadow: "0 0 10px white"}}>{d.name}</h3>
                  <p style={{ color: "567783", textShadow: "0 0 10px white", fontSize: "14px", fontFamily: "sans-serif", fontWeight: 'bold' }}>{d.text}</p>
                </div>
              </div>
            ))
            : "Carregando"}
        </div>
      </div>
    </div>
  );
};
